import React, { Component } from "react";
import GSForms from "./form-elements";
import { LANGUAGES } from "../data-structures/lang-codes";
import { TRANSLATIONS } from "../data-structures/localization";
import { MEASUREMENT_UNITS, ERROR_CODES } from "../data-structures/gs-constants";
import { HelperFunctions } from "../helpers/helper-functions";
import { TMSHelpers } from "../helpers/tms-helpers";
import { Loading } from "../common/loading-icon";
import { globalState, onGlobalStateChange } from "../store";
import { Validate } from "../helpers/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormHelpers from "../helpers/form-helpers";
import { UserData } from "../data-structures/user-data";

export default class EditUserForm extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.state = this.getInitialState();
	}

	getInitialState = (props = this.props) => ({
		id: props.selectedUser.id,
		firstName: props.selectedUser.firstName,
		lastName: props.selectedUser.lastName,
		username: props.selectedUser.username,
		emailAddress: props.selectedUser.emailAddress,
		emailVerified: props.selectedUser.emailVerified,
		units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === props.selectedUser.units),
		language: Object.values(LANGUAGES).find((x) => x.value === props.selectedUser.language),
		active: props.selectedUser.active,
		role: HelperFunctions.getRole(props.selectedUser.role),
		subscriptionExpirationDate: props.selectedUser.subscriptionExpirationDate,
		selectedGroups: props.selectedUser.groups.map((gId) => this.mapGroupToOption(globalState.groupList.find((g) => g.id === gId))),

		formErrors: [],
		responseError: null,

		submitting: false,
		saved: false,
	});

	componentDidMount() {
		this.unsubscribe = onGlobalStateChange(() => {
			if (globalState.userData) {
				const updatedSelf = this.state.id === globalState.userData.id;
				this.setState({
					...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
					...(this.state.responseError && {
						responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
					}),
					...(updatedSelf && {
						units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === globalState.userData.units),
						role: HelperFunctions.getRole(globalState.userData.role),
					}),
				});
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	componentDidUpdate(nextProps) {
		if (nextProps.editMode !== this.props.editMode) {
			this.setState(this.getInitialState());
		}
	}
	//#endregion

	mapGroupToOption = (group) => ({ label: group.name, value: group.id, id: group.id, ...group });

	handleSubmit = async () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ submitting: false, responseError: null, formErrors: formErrors });
		else {
			this.setState({ submitting: true, saved: false }, async () => {
				const selectedGroupIds = this.state.selectedGroups.map((g) => g.id);
				const updatedUser = new UserData({
					id: this.state.id,
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					username: this.state.username,
					emailAddress: this.state.emailAddress,
					units: this.state.units.value,
					language: this.state.language.value,
					active: this.state.active,
					role: this.state.role.value,
					subscriptionExpirationDate: this.state.subscriptionExpirationDate,
					groups: selectedGroupIds,
				});
				await TMSHelpers.editUserRecord(updatedUser).then((response) => {
					if (response.Success) {
						Promise.all(
							HelperFunctions.getUniqueValues([...this.props.selectedUser.groups, ...selectedGroupIds])
								.filter((gId) => {
									const userWasInGroup = this.props.selectedUser.groups.includes(gId);
									const userNowInGroup = selectedGroupIds.includes(gId);
									return (userWasInGroup && !userNowInGroup) || (!userWasInGroup && userNowInGroup);
								})
								.map(async (gId) => {
									const globalStateGroup = globalState.groupList.find((g) => g.id === gId);
									const groupShouldContainUser = this.state.selectedGroups.find((sg) => sg.id === gId);
									const newUserList = groupShouldContainUser
										? HelperFunctions.getUniqueValues([...Object.keys(globalStateGroup.userList), updatedUser.id])
										: Object.values(globalStateGroup.userList)
												.filter((u) => u.id !== updatedUser.id)
												.map((u) => u.id);
									return await TMSHelpers.updateGroupUserList(gId, newUserList);
								})
						).then(() => {
							TMSHelpers.refreshGlobalData();
							this.setState({
								submitting: false,
								saved: response.Success,
								...this.getErrorState(response),
								...(this.state.emailAddress !== this.getInitialState.emailAddress ? { emailVerified: false } : {}),
							});
						});
					} else {
						this.setState({ submitting: false, saved: response.Success, ...this.getErrorState(response) });
					}
				});
			});
		}
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (Validate.isNullOrEmpty(state.firstName)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["firstName"]));
		}

		if (Validate.isNullOrEmpty(state.lastName)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["lastName"]));
		}

		errors.push(...FormHelpers.getUsernameErrors(state.username, ["username"]));

		if (HelperFunctions.currentOrgIsCloudBased()) {
			if (Validate.isNullOrEmpty(state.emailAddress)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["emailAddress"]));
			} else if (!Validate.isValidEmailAddress(state.emailAddress)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.EMAIL_INVALID, ["emailAddress"]));
			}
		}

		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				case ERROR_CODES.USERNAME_TAKEN.key:
					return { formErrors: [new GSForms.FormError(response.errorMsg, ["username"])] };

				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	render() {
		const emailVerifiedField = HelperFunctions.currentOrgIsCloudBased() && (
			<GSForms.InfoField
				label={TRANSLATIONS.FORMS.EMAIL_VERIFIED}
				fieldValue={<FontAwesomeIcon icon={`fa-solid ${this.state.emailVerified ? "fa-check" : "fa-x"}`} />}
				formField
			/>
		);
		const subscriptionDateField = (
			<GSForms.InfoField
				label={TRANSLATIONS.USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE}
				fieldValue={
					this.state.subscriptionExpirationDate
						? new Date(this.state.subscriptionExpirationDate).toLocaleString(globalState.locale, {
								dateStyle: "short",
								timeStyle: "medium",
						  })
						: "-"
				}
				formField
			/>
		);
		const dateCreatedField = (
			<GSForms.InfoField
				label={TRANSLATIONS.USER_MANAGEMENT.DATE_CREATED}
				fieldValue={new Date(this.props.selectedUser.dateCreated).toLocaleString(globalState.locale, {
					dateStyle: "short",
					timeStyle: "medium",
				})}
				formField
			/>
		);
		const dateLastLoggedIn = (
			<GSForms.InfoField
				label={TRANSLATIONS.USER_MANAGEMENT.DATE_LAST_MODIFIED}
				fieldValue={new Date(this.props.selectedUser.dateLastLoggedIn).toLocaleString(globalState.locale, {
					dateStyle: "short",
					timeStyle: "medium",
				})}
				formField
			/>
		);
		const dateLastModified = (
			<GSForms.InfoField
				label={TRANSLATIONS.USER_MANAGEMENT.DATE_LAST_LOGIN}
				fieldValue={new Date(this.props.selectedUser.dateLastModified).toLocaleString(globalState.locale, {
					dateStyle: "short",
					timeStyle: "medium",
				})}
				formField
			/>
		);

		return (
			<div className="edit-user-form">
				<h2>
					{this.props.editMode ? TRANSLATIONS.HEADERS.EDIT_USER : TRANSLATIONS.HEADERS.USER_DETAILS}
					{this.props.editMode ? (
						<FontAwesomeIcon
							className="icon-btn"
							icon="fa-solid fa-arrow-rotate-left"
							onClick={(e) => {
								e.stopPropagation();
								this.props.toggleEditMode(false);
							}}
						/>
					) : (
						<FontAwesomeIcon
							className="icon-btn"
							icon="fa-solid fa-pencil"
							onClick={(e) => {
								e.stopPropagation();
								this.props.toggleEditMode(true);
							}}
						/>
					)}
				</h2>

				<form>
					{this.props.editMode ? (
						<>
							<GSForms.CheckBoxField
								label={TRANSLATIONS.USER_MANAGEMENT.ACTIVE}
								id="active-user"
								fieldValue={this.state.active}
								dataState="active"
								formField
								labelFirst
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleCheckboxChange(e, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.USER_MANAGEMENT.FIRST_NAME}
								fieldValue={this.state.firstName}
								dataState="firstName"
								formField
								errors={this.state.formErrors}
								handleChange={(value) => FormHelpers.handleChange(value, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.USER_MANAGEMENT.LAST_NAME}
								fieldValue={this.state.lastName}
								dataState="lastName"
								formField
								errors={this.state.formErrors}
								handleChange={(value) => FormHelpers.handleChange(value, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.USER_MANAGEMENT.USERNAME}
								tooltipContent={TRANSLATIONS.FORMS.USERNAME_REQUIREMENTS}
								fieldValue={this.state.username}
								dataState="username"
								formField
								errors={this.state.formErrors}
								handleChange={(value) => FormHelpers.handleChange(value, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.USER_MANAGEMENT.EMAIL_ADDRESS}
								fieldValue={this.state.emailAddress}
								dataState="emailAddress"
								formField
								errors={this.state.formErrors}
								handleChange={(value) => FormHelpers.handleChange(value, this)}
							/>
							{emailVerifiedField}
							<GSForms.SelectField
								label={TRANSLATIONS.USER_MANAGEMENT.UNITS_PREFERENCE}
								fieldValue={this.state.units}
								dataState="units"
								formField
								fieldOptions={Object.values(MEASUREMENT_UNITS)}
								errors={this.state.formErrors}
								handleChange={(option) => FormHelpers.handleSelectChange(option, "units", this)}
							/>
							<GSForms.SelectField
								label={TRANSLATIONS.USER_MANAGEMENT.LANGUAGE_PREFERENCE}
								fieldValue={this.state.language}
								dataState="language"
								formField
								fieldOptions={HelperFunctions.getSortedLanguages()}
								errors={this.state.formErrors}
								handleChange={(option) => FormHelpers.handleSelectChange(option, "language", this)}
							/>
							{HelperFunctions.loggedInAsAdmin() && (
								<GSForms.SelectField
									label={TRANSLATIONS.USER_MANAGEMENT.ROLE}
									fieldValue={this.state.role}
									dataState="role"
									formField
									fieldOptions={HelperFunctions.getAvailableRoles()}
									errors={this.state.formErrors}
									handleChange={(option) => FormHelpers.handleSelectChange(option, "role", this)}
								/>
							)}

							{globalState.orgData.isCloudStandardOrg &&
								(HelperFunctions.loggedInAsDeveloper() ? (
									<GSForms.DatePickerField
										formField
										dataState={"subscriptionExpirationDate"}
										label={TRANSLATIONS.USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE}
										fieldValue={this.state.subscriptionExpirationDate}
										isClearable
										errors={this.state.formErrors}
										handleChange={(selectedDate) => {
											this.setState({
												subscriptionExpirationDate: selectedDate ? new Date(selectedDate).setHours(23, 59, 59) : null,
											});
										}}
									/>
								) : (
									subscriptionDateField
								))}
							{dateCreatedField}
							{dateLastLoggedIn}
							{dateLastModified}

							<GSForms.MultiSelectField
								formField
								dataState={"selectedGroups"}
								label={TRANSLATIONS.USER_MANAGEMENT.GROUPS}
								fieldValue={this.state.selectedGroups}
								fieldOptions={globalState.groupList.map((group) => this.mapGroupToOption(group))}
								handleChange={(option) => FormHelpers.handleSelectChange(option, "selectedGroups", this)}
							/>

							<GSForms.ErrorContainer message={this.state.responseError} />
							{this.state.submitting && <Loading />}
							{this.state.saved && <GSForms.SavedCheck toggleVisible={() => this.setState({ saved: false })} />}

							<div className="flexbox">
								<GSForms.Button
									type="button"
									buttonText={TRANSLATIONS.FORMS.CANCEL}
									onClick={() => this.props.toggleEditMode(false)}
								/>
								<GSForms.Button onClick={this.handleSubmit} />
							</div>
						</>
					) : (
						<>
							<GSForms.InfoField
								label={TRANSLATIONS.USER_MANAGEMENT.ACTIVE}
								fieldValue={<FontAwesomeIcon icon={`fa-solid ${this.state.active ? "fa-check" : "fa-x"}`} />}
								formField
							/>
							<GSForms.InfoField label={TRANSLATIONS.USER_MANAGEMENT.FIRST_NAME} fieldValue={this.state.firstName} formField />
							<GSForms.InfoField label={TRANSLATIONS.USER_MANAGEMENT.LAST_NAME} fieldValue={this.state.lastName} formField />
							<GSForms.InfoField label={TRANSLATIONS.USER_MANAGEMENT.USERNAME} fieldValue={this.state.username} formField />
							<GSForms.InfoField label={TRANSLATIONS.USER_MANAGEMENT.EMAIL_ADDRESS} fieldValue={this.state.emailAddress} formField />
							{emailVerifiedField}
							<GSForms.InfoField label={TRANSLATIONS.USER_MANAGEMENT.UNITS_PREFERENCE} fieldValue={this.state.units.label} formField />
							<GSForms.InfoField
								label={TRANSLATIONS.USER_MANAGEMENT.LANGUAGE_PREFERENCE}
								fieldValue={this.state.language.label}
								formField
							/>
							<GSForms.InfoField label={TRANSLATIONS.USER_MANAGEMENT.ROLE} fieldValue={this.state.role.label} formField />

							{globalState.orgData.isCloudStandardOrg && subscriptionDateField}
							{dateCreatedField}
							{dateLastLoggedIn}
							{dateLastModified}

							<GSForms.InfoField
								label={TRANSLATIONS.USER_MANAGEMENT.GROUPS}
								fieldValue={this.state.selectedGroups.map((g) => (
									<div key={g.id}>{g.name}</div>
								))}
								className="groupField"
								formField
							/>
						</>
					)}
				</form>
			</div>
		);
	}
}
